<template>
  <!-- 分类顺序表新主页 -->
  <div class="leetcode">
    <div class="banner">
      <img src="@/assets/leetcode/bg.png" alt="" />
      <div class="pageTitle">LeetCode 分类顺序表</div>
    </div>
    <div class="mainArea">
      <div class="description">
        <template v-if="form.type == 1">
          <div class="title">《LeetCode 400题分类顺序表》</div>
          <div class="text">
            LeetCode 分类顺序表由3个表组成，分别是《LeetCode
            400题分类顺序表》《LeetCode 重点250题》《Data Science LeetCode
            精简版》
            <br />
            <br />
            <div>
              此表以先易后难 + 分类而成。Leetcode（https://leetcode.com/）
              并且类别分的并不是非常好，因为大量非最优解也涵盖在各种类别中。
              所以我们依据做题经验，最优解的类别，难度，重新划分。
              如果是第一次刷题的小伙伴，最好以本表的顺序为主，可以为大家节省时间，更有效率的做题，减少很多刷题的负担。
              <br />
              <br />
              注：本表非最终版本。《LeetCode
              千题视频讲解》课程中有最终Leetcode分类顺序表版本，更细致，每种题目对应方法一并写出，
              并且同类型做题方法归纳一起。
            </div>
          </div>
        </template>
        <template v-if="form.type == 5">
          <div class="title">《LeetCode 重点250题》</div>
          <div class="text">
            <div>
              这个重点题目是把Leetcode前400题进行精简，只保留高频250题，划分精简规则如下：
              <br />
              1. 删除不常考，面试低频出现题目
              <br />
              2. 删除重复代码题目（例：链表反转206题，代码在234题出现过）
              <br />
              3. 删除过于简单题目（例：100题：Same Tree）
              <br />
              4. 删除题意不同，代码基本相同题目（例：136 &amp; 389，保留一个）
              <br />
              所有题目尽量保证客观公正，只是按大概率删除不常考题目，很多题目面经出现过，
              但出现次数属于个位数或者只有一两家出现进行删除，这只是从概率上删除低频，简单题目，面试不一定会出现。
              旨在减轻大家的刷题负担，从400题减少到250题。
              <br />
              适用人群：有一定刷题基础，算法基础，二刷人群。
              <br />
              建议：400题全部刷完，再精刷这250题。
            </div>
          </div>
        </template>
        <template v-if="form.type == 6">
          <div class="title">《Data Science LeetCode 精简版》</div>
          <div class="text">
            <div>
              此表是针对 Data Science
              这个职位，对Leetcode前400题进行精简，划分精简规则如下：
              <br />
              1. 删除DS不常考，面试低频出现题目
              <br />
              2. 删除SDE考，过难题目
              <br />
              3. 删除高级数据结构算法，保留基础简单题目
              <br />
              4. 保留基础练手题目，提高代码能力
              <br />
              所有题目我们尽量保证客观公正，只是按大概率删除，目的是为了减轻DS的刷题负担。
              <br />
              适用人群：Data Science 职位相关人员
            </div>
          </div>
        </template>
      </div>
      <div class="filterArea">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-select v-model="form.type" @change="getChildSequenceTags">
              <el-option
                v-for="(type, index) in lcTypes"
                :key="index"
                :label="type.name"
                :value="type.id"
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span="20" style="display: flex; flex-wrap: no-wrap">
            <div
              :class="['filterTag', activeTag == 'all' ? 'active' : '']"
              @click="getAllList"
              style="height: 21px; white-space: nowrap"
            >
              全部
            </div>
            <div style="display: inline-block">
              <div
                :class="['filterTag', activeTag == tag.id ? 'active' : '']"
                v-for="(tag, index) in lcTags"
                :key="index"
                @click="changeActiveTag(tag)"
              >
                {{ tag.name }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <div class="questionList">
        <div class="filterInput">
          <el-input
            style="width: 300px; font-size: 14px"
            size="small"
            placeholder="请搜索题目"
            v-model="searchLcKey"
            @keypress.native.enter="searchSequenceData"
          ></el-input>
          <el-button
            icon="el-icon-search"
            type="primary"
            size="small"
            style="margin-left: 4px; vertical-align: top"
            @click="searchSequenceData"
          ></el-button>
          <!-- <el-select v-model="language" style="float: right">
            <el-option label="英文" value="en"></el-option>
            <el-option label="中文" value="ch"></el-option>
          </el-select> -->
          <span
            class="switchLanguage"
            style="float: right"
            @click="switchLanguage"
          >
            <svg
              viewBox="0 0 24 24"
              width="1em"
              height="1em"
              class="css-1lc17o4-icon"
            >
              <path
                fill-rule="evenodd"
                d="M12.87 15.07l-2.54-2.51.03-.03A17.52 17.52 0 0014.07 6H17V4h-7V2H8v2H1v1.99h11.17C11.5 7.92 10.44 9.75 9 11.35 8.07 10.32 7.3 9.19 6.69 8h-2c.73 1.63 1.73 3.17 2.98 4.56l-5.09 5.02L4 19l5-5 3.11 3.11.76-2.04zM18.5 10h-2L12 22h2l1.12-3h4.75L21 22h2l-4.5-12zm-2.62 7l1.62-4.33L19.12 17h-3.24z"
              ></path>
            </svg>

            切换到{{ language == "en" ? "中文" : "英文" }}</span
          >
        </div>
        <el-row :gutter="20">
          <el-col :span="18">
            <el-table :data="tableData" border>
              <el-table-column
                label="题号"
                prop="questionNum"
                width="70"
                align="center"
              ></el-table-column>
              <el-table-column
                label="题目"
                :prop="language == 'en' ? 'name' : 'chineseName'"
              >
                <template slot-scope="{ row }">
                  <span @click="goDetail(row)" style="cursor: pointer">
                    {{ language == "en" ? row.name : row.chineseName }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column
                label="通过率"
                prop="passingRate"
                width="100"
                align="center"
              >
                <template slot-scope="{ row }">
                  {{ row.passingRate }}%
                </template>
              </el-table-column>
              <el-table-column
                label="难度"
                prop="difficulty.name"
                width="100"
                align="center"
              >
                <template slot-scope="{ row }">
                  <span :class="[`difficulty${row.difficulty.value}`]">
                    {{ row.difficulty.name }}
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                label="说明"
                prop="type"
                width="150"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div class="content">
                    {{ row.type }}
                  </div>
                </template>
              </el-table-column>
<!--              <el-table-column label="视频" width="80" align="center">-->
<!--                <template slot-scope="{ row }">-->
<!--                  <div class="video">-->
<!--                    <div class="video-explanation" @click="jumpUrl(row)">-->
<!--                      <img-->
<!--                        style="width: 20px"-->
<!--                        src="@/assets/leetcode/video.png"-->
<!--                        alt=""-->
<!--                      />-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </el-table-column>-->
            </el-table>
            <!-- <el-pagination
              style="padding: 20px; text-align: center"
              :current-page.sync="currentPage"
              :total="total"
              layout="prev, pager, next"
              :page-size="20"
              @current-change="
                (page) =>
                  getSequenceData(
                    activeTag == 'all' ? form.type : activeTag,
                    page
                  )
              "
            >
            </el-pagination> -->
          </el-col>
          <el-col :span="6">
            <div class="discussionList">
              <div class="title">讨论区</div>
              <div class="discussList">
                <div
                  class="discusses"
                  v-for="(discuss, index) in discussList"
                  :key="index"
                >
                  <img class="useravatar" :src="discuss.avatar" alt="" />
                  <span class="username">{{
                    discuss.username ? discuss.username : "匿名用户"
                  }}</span>
                  <span class="createTime">
                    {{ discuss.createTime }}
                  </span>
                  <div class="discontent">
                    {{ discuss.content && discuss.content.content }}
                  </div>
                </div>
                <div
                  v-if="discussList.length == 0"
                  style="text-align: center; font-size: 18px; padding: 20px"
                >
                  暂无讨论
                </div>
              </div>
              <router-link to="/leetcodeClassification/discussion">
                <div class="more">查看更多</div>
              </router-link>
            </div>
            <SideAdvertisement></SideAdvertisement>
          </el-col>
        </el-row>
      </div>
    </div>
    <helpClass
      :visible="helpVisible"
      @setHelpVisible="(val) => (helpVisible = val)"
      :leetcode="true"
    ></helpClass>
    <leetDialog></leetDialog>
  </div>
</template>

<script>
import SideAdvertisement from "@/components/sideAdvertisement";
import {
  getSequenceTypes,
  getSequenceData,
  getDiscussion,
  browseLeetcode,
} from "@/service/leetcode";
import { mapState } from "vuex";
import helpClass from "@/views/ban/components/help";
import leetDialog from "@/components/leetDialog.vue";
export default {
  name: "LeetcodeChannel",
  components: { SideAdvertisement, helpClass, leetDialog },
  data() {
    return {
      lcTypes: [],
      lcTags: [],
      form: {
        type: "",
      },
      language: "en",
      activeTag: "all",
      currentPage: 1,
      total: 0,
      tableData: [],
      searchLcKey: "",
      discussList: [],
      helpVisible: false,
    };
  },
  methods: {
    getChildSequenceTags(id) {
      getSequenceTypes(id).then((res) => {
        if (res.success) {
          this.lcTags = res.result;
          this.getAllList();
        }
      });
    },
    getAllList() {
      this.searchLcKey = "";
      this.activeTag = "all";
      this.currentPage = 1;
      this.getSequenceData(this.form.type);
    },
    changeActiveTag(tag) {
      this.activeTag = tag.id;
      this.searchLcKey = "";
      this.currentPage = 1;
      this.getSequenceData(tag.id);
    },
    getSequenceData(id, page) {
      if (!page) {
        this.currentPage = 1;
      } else {
        this.currentPage = page;
      }
      getSequenceData(id, {
        name: this.searchLcKey,
        current: this.currentPage,
      }).then((res) => {
        if (res.success) {
          this.tableData = res.result;
          this.total = res.result.total;
        }
      });
    },
    jumpUrl(row) {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      if (!this.userInfo.watch) {
        this.helpVisible = true;
        return;
      }
      if (row.resourceId) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message:
            "讲解视频只有部分讲解开放<br>完整视频请购买《LeetCode千题视频讲解》课程",
          type: "warning",
        });
        this.$router.push(`/watchVideo?id=${row.id}`);
      } else {
        this.$confirm(
          "讲解视频只有部分讲解开放<br>完整视频请购买《LeetCode千题视频讲解》课程",
          "",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            center: true,
            dangerouslyUseHTMLString: true,
          }
        )
          .then(() => {
            this.$router.push("/course/2");
          })
          .catch(() => {});
      }
    },
    searchSequenceData() {
      this.getSequenceData(
        this.activeTag == "all" ? this.form.type : this.activeTag,
        1
      );
    },
    getDiscussion() {
      getDiscussion().then((res) => {
        if (res.success) {
          this.discussList = res.result.records;
        }
      });
    },
    goDetail(row) {
      browseLeetcode(row.id).then((res) => {
        if (res.success) {
          this.$router.push(
            `/leetcodeClassification/codeDetail?id=${row.id}&language=${this.language}`
          );
        }
      });
    },
    switchLanguage() {
      this.language = this.language == "en" ? "ch" : "en";
    },
  },
  mounted() {
    getSequenceTypes(0).then((res) => {
      if (res.success) {
        this.lcTypes = res.result;
        this.form.type = res.result[0].id;
        this.getChildSequenceTags(res.result[0].id);
      }
    });
    this.getDiscussion();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
};
</script>
<style lang="scss" scoped>
.leetcode {
  background: #f3f2ef;
}
.banner {
  position: relative;
  width: 100%;
  .pageTitle {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    font-size: 28px;
    color: #fff;
  }
  img {
    width: 100%;
    vertical-align: top;
    min-height: 100px !important;
    z-index: -1;
  }
}
.mainArea {
  width: 1200px;
  margin: -25px auto;
  transform: translateY(-50px) translateZ(2px);
  .description {
    padding: 40px 40px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background: #fff;
    .title {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 16px;
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #34495e;
      line-height: 30px;
    }
    br {
      padding: 12px;
    }
  }
}
.filterArea {
  padding: 30px;
  margin-top: 10px;
  border-radius: 12px;
  background: #fff;
  .filterTag {
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    display: inline-block;
    color: rgba(170, 170, 170, 0.8);
    line-height: 21px;
    padding: 0 10px;
    border-right: 1px solid rgba(120, 120, 128, 0.2);
    cursor: pointer;
    &:last-child {
      border: none;
    }
    &.active {
      color: #0075f6;
    }
  }
}
.questionList {
  .filterInput {
    padding: 10px 0;
  }
}
::v-deep .el-table th {
  background: #f4f4f4;
}
::v-deep .el-table th {
  padding: 8px;
}
::v-deep .el-table td {
  padding: 10px;
}
.content {
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.video-explanation {
  text-decoration: underline;
  cursor: pointer;
}
.discussionList {
  padding: 16px;
  background: #fff;
  margin-bottom: 8px;
  border-radius: 12px;
  .title {
    margin-top: 6px;
    position: relative;
    padding-left: 16px;
    line-height: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    padding-bottom: 12px;
    color: #34495e;
    &::before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 4px;
      height: 24px;
      background: #0084ff;
      border-radius: 2px;
    }
  }
  .more {
    font-size: 12px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #0075f6;
    line-height: 18px;
    padding-top: 12px;
    text-decoration: underline;
  }
  .discusses {
    padding: 6px 0;
    border-bottom: 1px solid rgba(235, 236, 241, 0.91);
    .useravatar {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      vertical-align: top;
      margin-right: 4px;
    }
    .username {
      font-size: 12px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #34495e;
      line-height: 18px;
      margin-right: 4px;
    }
    .createTime {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: rgba(52, 73, 94, 0.5);
      line-height: 18px;
    }
    .discontent {
      font-size: 12px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #828282;
      line-height: 18px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
.switchLanguage {
  line-height: 32px;
  cursor: pointer;
  font-size: 14px;
}
::v-deep .el-message-box__btns {
  padding-top: 15px;
}
.difficulty0 {
  color: #00af9b;
}
.difficulty1 {
  color: #ffb800;
}
.difficulty2 {
  color: #ff2d55;
}
</style>